<template>
  <div>
    <!-- Page Header -->
    <base-page-header
      :title="title ? title : '...'"
      backLink="pre_approval_letters">
      <template #buttons>
        <div class="d-flex justify-content-md-end align-items-end mt-3 mt-md-0">
          <base-button title="Update Preview" action="secondary" @click-btn="preparePreview" />
          <base-button title="Delete" action="secondary" @click-btn="deleteLetter(letterId)" :loading="ui.deleting" class="mx-2" />
          <base-button title="Update" action="primary" @click-btn="updateLetter" v-if="showUpdate" :loading="ui.saving" class="me-2" />
          <base-button-dropdown title="Download" @click-btn="downloadLetterRequest(letterId)" :loading="ui.downloading" action="primary"
                                @click-sub-btn="ui.showDownloadSettingsModal = true">
          </base-button-dropdown>
        </div>
      </template>
    </base-page-header>

    <!--  Page Breadcrumbs  -->
    <base-breadcrumbs :crumbs="breadcrumbs" />

    <!--  Page Spinner  -->
    <page-spinner v-if="ui.loading" class="mt-3" />

    <!--  Page Content  -->
    <base-page-content class="p-0" v-else>
      <template #content>
        <div class="row pt-0 w-100 m-auto">
          <div class="col-sm-6 col-lg-8 border-light-md-right">
            <pre-approval-letter-preview />
          </div>
          <div class="col-sm-6 col-lg-4 p-0">
            <ValidationErrorsList
              class="m-2"
              :error="ui.error"
              :errors="ui.errors"
              :isError="ui.isError"
            />
            <pro-approval-letter-prospect
              :borrowerData="borrower"
              :coBorrowerData="coBorrower"
              :source="source"
              :loanAmount="loanAmount"
              :purchase-price="purchasePrice"
              :downPayment="downPayment"
              :program="program"
              :propertyAddress="propertyAddress"
              @update-borrower="updateBorrower"
              @update-coBorrower="updateCoBorrower"
              @update-source="updateSource"
            />
            <pro-approval-letter
              :letterSubject="letterSubject"
              :letterTo="letterTo"
              @update-letter-subject="updateLetterSubject"
              @update-toField="updateToField"
            />
            <pro-approval-letter-m-l-o
              :selectedAgent="selectedAgent"
              @update-agent="updateAgent"
            />
          </div>
        </div>
      </template>
    </base-page-content>
    <transition name="fade">
      <download-settings-modal v-if="ui.showDownloadSettingsModal"
                               @save="saveSettings"
                               @close="ui.showDownloadSettingsModal = false"
      />
    </transition>
  </div>
</template>

<script>
import BaseBreadcrumbs from "../../components/BaseBreadcrumbs";
import BasePageHeader from "../../components/BasePageHeader";
import BasePageContent from "../../components/BasePageContent";
import PreApprovalLetterPreview from "./includes/PreApprovalLetterPreview";
import ProApprovalLetterProspect from "./includes/PreApprovalLetterProspect";
import ProApprovalLetter from "./includes/PreApprovalLetter";
import ProApprovalLetterMLO from "./includes/ProApprovalLetterMLO";
import PageSpinner from "../../components/pageSpinner";
// import { getBackendUrl } from "@/utils/backendUrl";
import ValidationErrorsList from "@/components/form/ValidationErrorsList";
import BaseButton from '../../components/BaseButton.vue';
import BaseButtonDropdown from '../../components/BaseButtonDropdown.vue';
import DownloadSettingsModal from "./modals/DownloadSettingsModal.vue";

export default {
  name: "PreApprovalLetterSingle",
  components: {
    DownloadSettingsModal,
    PageSpinner,
    ProApprovalLetterMLO,
    ProApprovalLetter,
    ProApprovalLetterProspect,
    PreApprovalLetterPreview,
    BasePageContent,
    BaseBreadcrumbs,
    BasePageHeader,
    ValidationErrorsList,
    BaseButton,
    BaseButtonDropdown
  },
  data() {
    return {
      breadcrumbs: [
        { title: "Resource Center", link: "resource_center" },
        { title: "Pre-Approved Letters", link: "pre_approval_letters" },
        { title: "Pre-Approval Letter", link: "pre_approval_letter_create" }
      ],
      title: "",
      letterId: this.$route.params.id ? this.$route.params.id : null,
      loanAmount: "0",
      purchasePrice: "0",
      downPayment: "0",
      program: "",
      source: "custom",
      letterSubject: "",
      letterTo: "",
      propertyAddress: "",
      borrower: {
        firstName: "",
        lastName: ""
      },
      coBorrower: {
        firstName: "",
        lastName: ""
      },
      selectedAgent: {},
      previewData: {},
      ui: {
        saving: false,
        loading: false,
        deleting: false,
        downloading: false,
        isError: false,
        error: "",
        showDownloadSettingsModal: false,
        errors: []
      },
      downloadSettings: {
        letter: false,
        guide: false,
        handbook: false
      }
    };
  },
  methods: {
    // Show Preview
    preparePreview() {
      this.previewData = {
        letterSubject: this.letterSubject,
        letterTo: this.letterTo,
        borrowerFirstName: this.borrower.firstName,
        borrowerLastName: this.borrower.lastName,
        coBorrowerFirstName: this.coBorrower.firstName,
        coBorrowerLastName: this.coBorrower.lastName,
        propertyAddress: this.propertyAddress,
        loanAmount: this.loanAmount,
        purchasePrice: this.purchasePrice,
        downPayment: this.downPayment,
        program: this.program,
        mloPhoneNumber: this.selectedAgent.phone,
        mloSignatureText: this.selectedAgent.signature,
        mloFirstName: this.selectedAgent.firstName,
        mloLastName: this.selectedAgent.lastName,
        mloPosition: this.selectedAgent.position,
        mloNmls: this.selectedAgent.nmls,
        mloEmail: this.selectedAgent.email
      };

      this.$root.$emit("show-letter-preview", this.previewData);
    },
    // Update Data section
    updateBorrower(borrower) {
      if (borrower !== null) {
        this.borrower.firstName = borrower.firstName ? borrower.firstName : "";
        this.borrower.lastName = borrower.lastName ? borrower.lastName : "";
        this.loanAmount = borrower.amount ? borrower.amount : "0";
        this.purchasePrice = borrower.purchasePrice ? borrower.purchasePrice : "0";
        this.downPayment = borrower.downPayment ? borrower.downPayment : "0";
        this.program = borrower.program ? borrower.program : "";
        this.propertyAddress = borrower.address ? borrower.address : "";

        setTimeout(() => {
          this.title = this.borrower.firstName + " " + this.borrower.lastName;
        }, 800);
      } else {
        this.borrower.firstName = "";
        this.borrower.lastName = "";
        this.loanAmount = "0";
        this.propertyAddress = "";
      }
    },
    updateCoBorrower(coBorrower) {
      if (coBorrower !== null) {
        this.coBorrower.firstName = coBorrower.firstName
          ? coBorrower.firstName
          : "";
        this.coBorrower.lastName = coBorrower.lastName
          ? coBorrower.lastName
          : "";
      } else {
        this.coBorrower.firstName = "";
        this.coBorrower.lastName = "";
      }
    },
    updateSource(source) {
      this.source = source;
    },
    updateLoanAmount(amount) {
      this.loanAmount = amount;
    },
    updateAddress(address) {
      this.propertyAddress = address;
    },
    updateLetterSubject(subject) {
      this.letterSubject = subject;
    },
    updateToField(toField) {
      this.letterTo = toField;
    },
    updateAgent(agent) {
      this.selectedAgent = agent;
      this.preparePreview();
    },
    updateLetter() {
      this.ui.saving = true;

      let formData = new FormData();
      formData.append("source", this.source);
      formData.append("loanAmount", this.loanAmount);
      formData.append("purchasePrice", this.purchasePrice);
      formData.append("downPayment", this.downPayment);
      formData.append("program", this.program);
      formData.append("letterSubject", this.letterSubject);
      formData.append("letterTo", this.letterTo);
      formData.append("propertyAddress", this.propertyAddress);
      formData.append("borrowerFirstName", this.borrower.firstName);
      formData.append("borrowerLastName", this.borrower.lastName);
      formData.append("coBorrowerFirstName", this.coBorrower.firstName);
      formData.append("coBorrowerLastName", this.coBorrower.lastName);
      formData.append("mloId", this.selectedAgent.id);
      formData.append("mloFirstName", this.selectedAgent.firstName);
      formData.append("mloLastName", this.selectedAgent.lastName);
      formData.append("mloPosition", this.selectedAgent.position);
      formData.append("mloNmls", this.selectedAgent.nmls);
      formData.append("mloPhoneNumber", this.selectedAgent.phone);
      formData.append("mloEmail", this.selectedAgent.email);
      formData.append("mloSignatureText", this.selectedAgent.signature);

      this.ui.isError = false;
      this.ui.error = "";
      this.ui.errors = [];

      this.$http
        .post(`/api/v1/pre-approved-letter/${this.letterId}/edit`, formData)
        .then(res => {
          this.alertSuccess(res.data.message);
          this.getData();
        })
        .catch(err => {
          if (err.response.data) {
            let validation = this.getErrorsFromResponse(err.response);
            this.ui.isError = true;
            this.ui.error = validation.error;
            this.ui.errors = validation.errors;
            this.alertError(err.response.data.message);
          } else {
            this.alertError(err.response.statusText);
          }
        })
        .finally(() => (this.ui.saving = false));
    },
    getData() {
      this.ui.loading = true;

      this.$http
        .get(`/api/v1/pre-approved-letter/${this.letterId}/show`)
        .then(res => {
          this.loanAmount = res.data.loanAmount;
          this.purchasePrice = res.data.purchasePrice;
          this.downPayment = res.data.downPayment;
          this.program = res.data.program;
          this.source = res.data.source;
          this.letterSubject = res.data.letterSubject;
          this.letterTo = res.data.letterTo;
          this.propertyAddress = res.data.propertyAddress;

          this.borrower.firstName = res.data.borrowerFirstName;
          this.borrower.lastName = res.data.borrowerLastName;

          this.coBorrower.firstName = res.data.coBorrowerFirstName;
          this.coBorrower.lastName = res.data.coBorrowerLastName;

          this.selectedAgent = res.data.mlo;
          this.selectedAgent.fullName = res.data.mlo.firstName + " " + res.data.mlo.lastName;
          this.selectedAgent.phone = res.data.mlo.phoneNumber;
          this.selectedAgent.signature = res.data.mlo.signatureText;

          setTimeout(() => {
            this.title = this.borrower.firstName + " " + this.borrower.lastName;
            this.preparePreview();
          }, 800);
        })
        .catch(err => this.alertError(err.response.statusText))
        .finally(() => (this.ui.loading = false));
    },
    deleteLetter(id) {
      if (confirm("Are you sure?")) {
        this.ui.deleting = true;
        this.$http
          .delete(`/api/v1/pre-approved-letter/${id}/delete`)
          .then(() => {
            this.ui.deleting = false;
            this.$router.push("/resource-center/pre-approval-letters");
          })
          .catch(() => this.pushAlert("error", "Failed Generate"));
      }
    },
    downloadLetter(id, withBuyer = false) {
      this.ui.downloading = true;
      if (withBuyer) {
        this.$http
          .get(`/api/v1/pre-approved-letter/${id}/download?withBuyer=1`, {
            responseType: "arraybuffer"
          })
          .then(res => {
            let fileURL = window.URL.createObjectURL(
                new Blob([res.data], {
                  type: "application/zip"
                })
              ),
              fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "Pre-approval Letter + Home Buyer Guide.zip");

            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
          })
          .catch(() => this.pushAlert("error", "Failed Generate"))
          .finally(() => (this.ui.downloading = false));
      } else {
        this.downloadLetterRequest(id)
      }
    },
    downloadLetterRequest(id) {
      this.$http
        .get(`/api/v1/pre-approved-letter/${id}/download-new?withBuyer=0`, {
          responseType: "arraybuffer",
          params: {
            letter: this.downloadSettings.letter,
            handbook: this.downloadSettings.handbook,
            guide: this.downloadSettings.guide,
            withBuyer: 0
          }
        })
        .then((res) => {
          // check if selected more than 1 document
          let downloadZip = Object.values(this.downloadSettings).filter(val => val === true).length > 1
          let newBlob = new Blob([res.data], {
            type: downloadZip ? "application/zip" : "application/pdf"
          });
          let fileURL = window.URL.createObjectURL(newBlob);
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;

          let borrowerName = this.borrower.firstName + " " + this.borrower.lastName;
          let fileName = "Pre-approval Letter.pdf"
          if (downloadZip) {
            fileName = "Pre-approval Letter.zip"
          } else {
            this.downloadSettings.letter ? fileName = `${borrowerName} - Pre-approval Letter.pdf` : ""
            this.downloadSettings.handbook ? fileName = `${borrowerName} - Home Loan Toolkit.pdf` : ""
            this.downloadSettings.guide ? fileName = `${borrowerName} - Home Buyer Guide.pdf` : ""
          }
          fileLink.setAttribute("download", fileName);

          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => {
          console.log(e)
          this.pushAlert("error", "Failed Generate")
        })
        .finally(() => {
          this.ui.downloading = false
          this.downloadSettings = {
            letter: false,
            guide: false,
            handbook: false
          }
        });
    },
    downloadHomeLoanToolkit(id) {
      this.$http
        .get(`/api/v1/pre-approved-letter/${id}/download-toolkit`, {
          responseType: "arraybuffer"
        })
        .then(res => {
          let fileURL = window.URL.createObjectURL(
              new Blob([res.data], {
                type: "application/pdf"
              })
            ),
            fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "home_loan_toolkit_.pdf");

          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        })
        .catch(() => this.pushAlert("error", "Failed Generate"))
        .finally(() => this.letter[0].isDownloading = false)
    },
    saveSettings(data) {
      this.downloadSettings = data;
      this.downloadLetterRequest(this.letterId)
      this.ui.showDownloadSettingsModal = false;
    }
  },
  computed: {
    showUpdate() {
      return (
        this.borrower.firstName &&
        this.letterSubject.length &&
        this.letterTo &&
        this.selectedAgent !== null
      );
    }
  },
  beforeMount() {
    this.getData();
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-top: 12px;

  .approval__delete {
    min-width: 90px;
    @media (max-width: 568px) {
      width: 50%;
    }
  }
}
.base-button-text {
  transition: all .3s;
  cursor: pointer;
  &:hover {
    background: rgba(0,0,0, .05);
  }
  span {
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
  }
}
</style>
