<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="close"></div>

    <div class="modal-block">
      <div class="modal-header">
        <h3>Download Settings</h3>
      </div>
      <div class="modal-body">
        <div class="row mb-5">
          <div class="col-12">
            <div class="full-width form-check">
              <div>
                <input id="preApprovalLetter" v-model="settings.letter" class="form-check-input" type="checkbox"/>
              </div>
              <label class="mb-1" for="preApprovalLetter">Pre-Approval Letter</label>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="full-width form-check">
              <div>
                <input id="handbookField" v-model="settings.handbook" class="form-check-input" type="checkbox"/>
              </div>
              <label class="mb-1" for="handbookField">Home Loan Took Kit</label>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="full-width form-check">
              <div>
                <input id="guideField" v-model="settings.guide" class="form-check-input" type="checkbox"/>
              </div>
              <label class="mb-1" for="guideField">Home Buyer Guide</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button title="Save" action="secondary-default" type="submit" @click-btn="save" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../../../components/BaseButton.vue";

export default {
  name: 'DownloadSettingsModal',
  components: {BaseButton},
  data() {
    return {
      settings: {
        letter: false,
        handbook: false,
        guide: false
      }
    }
  },
  methods: {
    save() {
      this.$emit('save', this.settings)
      this.reset()
    },
    close() {
      this.$emit('close')
      this.reset()
    },
    reset() {
      this.settings = {
        letter: false,
        handbook: false,
        guide: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-check {
  display: flex;
  align-items: center;
  margin: 0;
  input.form-check-input {
    height: 18px;
    width: 18px;
    max-width: 18px;
    margin-right: 10px;
  }
  label {
    margin-bottom: 0 !important;
  }
}
.modal {
  .modal-block {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
  }
  .modal-body {
    padding: 30px;
    margin-left: 1rem;
    .r-2 {
      right: 1rem !important;
    }
  }
  .modal-header {
    position: relative;
  }
  .modal-footer {
    position: absolute;
  }
}

.check-block {
  display: flex;
  align-items: center;
  border: 1px solid rgba(231, 232, 232, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 12px;
  width: fit-content;

  &.active {
    border: 1px solid rgba(1, 122, 255, 1);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  label {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 0 0 0 12px;
    white-space: nowrap;
    cursor: pointer;
  }

  .form-check-input {
    height: 18px;
    max-width: 18px;
    margin: 0;
    cursor: pointer;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}
::v-deep .daterange-dashboard {
  max-width: 100%;
  .reportrange-text {
    line-height: 22px!important;
    max-width: 100%;
  }
  .single.show-ranges .drp-calendar.left {
    border: none!important;
  }
}
::v-deep {
  .multiselect {
    min-height: 36px;
    max-height: 36px;
    min-width: 180px;
    width: 100%;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all .3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 8px 40px 0 8px;
      transition: all .3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 15%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all .3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}
</style>
