<template>
    <div class="base-dropdown" :class="[action, disabled]">
        <button class="base-dropdown-button" @click="$emit('click-btn')">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
            {{ title }}
        </button>
        <button class="base-dropdown-toggler" :name="dropdownName" v-if="dropdownName">
            <div class="base-dropdown-toggler--triangle"></div>
        </button>
      <button class="base-dropdown-toggler" @click="$emit('click-sub-btn')" v-else>
        <div class="base-dropdown-toggler--triangle"></div>
      </button>
        <tippy
            :to="dropdownName"
            trigger="click"
            arrowType="round"
            boundary="window"
            interactive="true"
            animateFill="false"
            distance="5"
            placement="bottom-end"
            zIndex="99"
            hideOnClick="true"
            theme="light"
            class="base-dropdown-tippy"
            sticky="true"
          >
          <div class="row base-dropdown--body w-100 mx-auto">
            <slot name="body"></slot>
          </div>
        </tippy>
    </div>
</template>

<script>
export default {
    name: 'BaseButtonDropdown',
    props: {
        title: String,
        dropdownName: String,
        action: String,
        loading: Boolean,
        disabled: Boolean
    }
}
</script>
